//colors
$primary: #7367f0;
$dark-grey: #363636;
$light-color: #fff;
$text-color:#565656;
$bg-body:#f8f8f8;




//colors dark
$primary-dark: #7367f0; //GENERAL TEXTO
$dark-grey-dark: #d2d3d0; //PARTE DE ABAJO SATURDAY
$light-color-dark: #121212; //FONDO DEL SIDEBAR Y SATUDARY
$text-color-dark:rgb(196, 189, 189); //COLOR DEL TEXTO SIDEBAR Y SATURDAY
$bg-body-dark: #151E21; //FONDO PARTE DEL CENTRO


//$dark-grey-dark: #363636;  PARTE DE ABAJO -> SATURDAY
//$light-color-dark: rgb(243, 79, 79); PARTE DEL SIDEBARD Y SATURAY -> BACKGROUND
//$text-color-dark: rgb(243, 79, 79); PARTE DEL SIDEBAR Y SATURAY -> LETRA Y REGISTER
//$bg-body:#f8f8f8; ES LA PARTE DEL CENTRO EL NEGRITO



//



//button google
$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669f2;