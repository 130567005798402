.nothing__main-content {
    background-color: $bg-body;
    color: $text-color;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: center;
    height: 75vh;
    width: 100%;

    p {
        text-align: center;
    }
}

.darkMode{
    background-color: $bg-body-dark;
}