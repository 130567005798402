.auth__main {
    align-items: center;
    background-color: $primary;
    display: flex;
    justify-content: center;
    margin: 0px;
    height: 100vh;
    width: 100vw;
}


.auth_main_dark{
    align-items: center;
    background-color: $primary;
    display: flex;
    justify-content: center;
    margin: 0px;
    height: 100vh;
    width: 100vw;
    background-color: #121212;
}

.auth__box-container {
    background-color: white;
    border-radius: 15px;
    padding: 90px;
    width: 260px;
    // text-align: center;
}

.auth__box-container__dark {
    background-color: #151E21;
    border-radius: 15px;
    padding: 90px;
    width: 260px;
    // text-align: center;
}



.auth__title {
    color: darken($color: $primary, $amount: 20);
    margin-bottom: 30px;
}

.auth__title__dark {
    color: #fff;
    margin-bottom: 30px;
}

.auth__title_app {
    color: darken($color: $dark-grey, $amount: 20);
    margin-bottom: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.auth__title_app__dark {
    color: #fff;
    margin-bottom: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.auth__input {
    color: $text-color;
    border: 0px;
    border-bottom: 1px solid #ccc;
    font-size: 18px;
    margin-bottom: 15px;
    height: 48px;
    width: 100%;
    padding: .5rem;
    border-radius: 4px;
    transition: border-bottom .3s ease;
    box-sizing: border-box;

    &:focus {
        border-bottom: 1px solid $primary;
        outline: none;
    }
}

.auth__input__dark {
    background-color: #121212;
    color: $text-color;
    border: 0px;
    border-bottom: 1px solid #000;
    font-size: 18px;
    margin-bottom: 15px;
    height: 48px;
    width: 100%;
    padding: .5rem;
    border-radius: 4px;
    transition: border-bottom .3s ease;
    box-sizing: border-box;

    &:focus {
        border-bottom: 1px solid $primary;
        outline: none;
    }
}



a.link{
    color: $primary;
    padding-bottom: 20px;
    
}

.link__dark{
    color: #ccc;
    padding-bottom: 20px; 
    text-align: center;  
}



.gpassword-field{
    position: relative;
    .auth__input{
        position: relative;
    }
    .icon-eye{
        position: absolute;
        top: -0.1rem;
        right: .5rem ;
        margin-top: 5px;
        color: $text-color;
    }
}
.auth__social-networks {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 20px;
    width: 100%;
    color: $text-color;
}

.auth__logo {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 20px;
    width: 100%;
    color: $text-color;
}

.auth__alert-error {
    background-color: red;
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    padding: 5px;
}

.auth__alert-error__dark {
    background-color: rgb(49, 12, 151);
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    padding: 5px;
}



.password-icon {
    position: absolute;
    left: auto;
    right: 950px;
    text-indent: 32px;
    top: 505px;
    cursor: pointer;
}