.notes__main-content {
  display: flex;
  flex-direction: column;
  background-color: $bg-body;
  height: 100%;
  //overflow-y:hidden;
}

.notes__appbar {
  align-items: center;
  background-color: $light-color;
  color: $text-color;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
}

.notes__appbar_dark {
  align-items: center;
  background-color: $light-color-dark;
  color: $text-color-dark;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
}

.notes__content {
  display: flex;
  flex-direction: row;
  // height: 100%;
  padding: 20px;

  &-col {
    width: 70%;
    display: flex;
    flex-direction: column;
  }
}

.notes__content__pass {
  display: flex;
  flex-direction: row;
  // height: 100%;
  padding: 20px;

  &-col {
    width: 95%;
    display: flex;
    flex-direction: column;
  }
}

.notes__btn-content {
  padding: 0 20px;
}

.notes__title-input,
.notes__textarea {
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  box-shadow: 2px 2px 4px hsl(0, 0%, 91%);
  &:focus {
    outline: none;
  }
}

.notes__title-input::placeholder,
.notes__textarea::placeholder {
  color: hsl(0, 0%, 70%);
  font-weight: 500;
}

.notes__title-input {
  color: $dark-grey;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  flex-wrap: wrap;
  flex: 1 1 auto;
}

//DARK MODE

.notes__title-input__darkMode {
  border: 1px solid #000;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  box-shadow: 2px 2px 4px hsl(0, 3%, 6%);
  &:focus {
    outline: none;
  }

  color: $dark-grey-dark;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  flex-wrap: wrap;
  flex: 1 1 auto;
  background-color: #121212;
}

.notes__textarea__darkMode {
  border: 1px solid #000;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  box-shadow: 2px 2px 4px hsl(0, 3%, 6%);
  &:focus {
    outline: none;
  }
  color: $dark-grey-dark;
  font-size: 18px;
  font-weight: 500;
  height: 100px;
  resize: none;
  background-color: #121212;
}

.notes__textarea {
  color: $dark-grey;
  font-size: 18px;
  // flex: 1 1 auto;
  font-weight: 500;
  height: 100px;
  resize: none;
}

.notes__image img {
  margin-left: 2rem;
  box-shadow: 5px 5px $dark-grey;
  height: 150px;
}

.notes__image video {
  margin-left: 2rem;
  box-shadow: 5px 5px $dark-grey;
  height: 150px;
}

.darkMode {
  background-color: $bg-body-dark;
}

.notes__label {
  font-weight: bold;
  color: #575757;
  margin-bottom: 7px;
}

.notes__label__darkMode {
  font-weight: bold;
  margin-bottom: 7px;
  color: $dark-grey-dark;
}

//EJEMPLO
.form__group {
  border: 1px solid #cccccc;
  position: relative;
  padding: 22px 10px 0;
  border-radius: 10px;
  background-color: #fff;
}

.form__group__darkMode {
  border: 1px solid transparent;
  position: relative;
  padding: 22px 10px 0;
  border-radius: 10px;
  background-color: #121212;
}

.form__field__darkMode {
  resize: none;
  font-family: inherit;
  width: 100%;
  border: 0;
  outline: 0;
  font-size: 18px;
  padding: 7px 0;
  color: $dark-grey-dark;
  background: transparent;
  transition: border-color 0.2s;
  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 18px;
    cursor: text;
    top: 14px;
  }
}

.form__field {
  resize: none;  
  font-family: inherit;
  width: 100%;
  border: 0;
  outline: 0;
  font-size: 18px;
  padding: 7px 0;
  color: $dark-grey;
  background: transparent;
  transition: border-color 0.2s;
  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 18px;
    cursor: text;
    top: 14px;
  }
}

.form__label {
  margin-top: 3px;
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 18px;
  color: #8b8b8b;
}
