

.global__main-content {
    display: flex;
    flex-direction: row;
}
.global__main-sidebar{
    display: flex;
    flex-direction: row;
    height: 100vh;
}
.global__main-col{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: $bg-body;
}




.global__sidebar {
    background-color: $light-color;
    color: white;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 260px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
}




.global__sidebar__items {
    background-color: $white;
    color: white;
    display: flex;
    flex-direction: column;
    // height: 25vh;
    // padding-left: 10px;
    // padding-right: 10px;
}
.global__sidebar__row{
    display: flex;
    flex-direction: row;
}

.global__sidebar-navbar2 {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    color: $text-color;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    i{
        color: $primary;
    }
    span{
        color: $primary;
    }
}

.global__sidebar-navbar {
    display: flex;
    flex-direction: row;
    background-color: #f8f8f8;
    color: $text-color;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    // margin: .1rem;
    box-sizing: border-box;

    i{
        color: $primary;
    }
    h3{
        color: $primary; 
        display: flex;
        align-items: center;
    }
    span{
        color: $primary;
        margin-left: .5rem;
    }
}



.global__sidebar-navbar h3 {
    font-weight: lighter;
}

.global__new-entry {
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    transition: color .3s ease;

    &:hover {
        color: darken($color: white, $amount: 20);
    }
    i{
        padding-right: 1rem;
        color: $primary;
    }
    p{
        color: $primary;
    }
    .logo{
        color: $primary;
    }
}
.global__nav-menu{
    margin-top: 20px;
    ul{
        padding: 0;
    }
    .nav-text {
        display: flex;
        justify-content: start;
        align-items: center;
        // padding: 8px 0px 8px 16px;
        list-style: none;
        height: 60px;
      }
      
      .nav-text a {
        text-decoration: none;
        color: $text-color;
        font-size: 18px;
        width: 95%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border-radius: 4px;
        svg{
            padding-right: 1rem;
        }
      }      
     
      
      .nav-text a:hover {
        background-color: $primary;
        color: $light-color;
      }    
      
}

.global__nav-menu_darkMode{
    margin-top: 20px;
    ul{
        padding: 0;
    }
    .nav-text {
        display: flex;
        justify-content: start;
        align-items: center;
        // padding: 8px 0px 8px 16px;
        list-style: none;
        height: 60px;
      }
      
      .nav-text a {
        text-decoration: none;
        color: $text-color-dark;
        font-size: 18px;
        width: 95%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border-radius: 4px;
        svg{
            padding-right: 1rem;
        }
      }      
     
      
      .nav-text a:hover {
        background-color: $primary;
        color: $light-color;
      }    
      
}



// TODO scroll-x for items-entries
.global__entries {
    display: flex;
    flex-direction: row;    
    width: 100%;    
    overflow-y: scroll;
    flex-wrap: wrap;
    
}

.global__entry {
    background-color: white;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    margin: .5rem;
    color: $dark-grey;    
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    overflow: hidden;   
    width: 290px;
    height: 150px;

    .w150{
        width: 100%;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}

//Global__entry__darkMode bg
.global__entry__darkMode{
    background-color: $light-color-dark;
    color: $dark-grey-dark;
    width: 290px;
    height: 150px;
}
//

.global__entry-picture {
    height: 100%;
    width: 50%;
}

.global__entry-body {
    padding: 10px;
}

.global__entry-title {
    font-size: 30px;
    font-weight: bold;
    color: $dark-grey;
    text-overflow: ellipsis;
}

.global__entry-title__darkMode{
    font-size: 30px;
    font-weight: bold;
    color: $dark-grey-dark;
    text-overflow: ellipsis;
}


.global__entry-content {
    font-size: 15px;
}

.global__entry-date-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    h4{
        font-size: 12px;
        color: $dark-grey;
    }

}

.global__entry-date-box__darkMode{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    h4{
        font-size: 12px;
        color: $dark-grey-dark;
    }

}

.global__entry-date-box span{
    font-size: 10px;
    color: $dark-grey;
}

.global__entry-date-box__darkMode span{
    font-size: 10px;
    color: $dark-grey-dark;
}



//@at-root
*{
    margin: 0;
    padding: 0;
  }
  
  .page{
    height: 100vh;
    background-color: #512da8;
    display: flex;
    align-items: center;
    justify-content: center;
  
  }
  .container{
    height: 200px;
    width: 200px;
    border-radius: 10px;
    background-color: rgba(104, 70, 70, 0.5);
    
  }
  .heading{
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 1rem;
    font-weight: bold;
  
  }
  .img-holder{
    margin: auto;
    width: 150px;
    height: 150px;
    border: 3px black solid;
    border-radius: 5px;
    margin-top: 1rem;
  }
  .img{
    width: 150px;
    height: 150px;
    object-fit: cover;
  }
  #input[type="file"] {
      display: none;
  }
  .label{
    width: 100%;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }
  .image-upload{
    margin: auto;
    width: 200px;
      height: 40px;
    color: white;
    border-radius: 20px;
    background-color: rgb(83, 7, 206);
    text-align: center;
    cursor: pointer;
  }


.darkModeP {
    background-color: $light-color-dark;
    height: 100vh;
}

.darkMode{
    background-color: $bg-body-dark;
}