* {
    font-family: Arial, Helvetica, sans-serif;
    margin: 0px;
}

html, body {
    height: 100vh;
    width: 100vw;
}

main {
    width: 100%;
}

.darkBody {
    background-color: #000;
}

.mt-1{
    margin-top: 5px;
}

.mt-5 {
    margin-bottom: 5px; 
}

.mb-5 {
    margin-bottom: 20px;
}
.pl-2{
    padding-left: 10px;
}
.pointer {
    cursor: pointer;
}
.three-col:not(:last-child){
    margin-right: 1%;
}
.form-group{
    display: flex;
    flex-wrap: wrap;
}

.css-1xqwaie-control{
    height: 55px;
}