.link {
    color: $dark-grey;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    } 
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}