.btn {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 12px;
    padding: 1rem;
    transition: color .3s ease;

    &:focus {
        outline: none;
    }

    &:hover {
        color: darken($color: #a1a1a1, $amount: 10);
    }
}

.btn-primary {
    background-color: $primary;
    border-radius: 4px;

    &:disabled {
        background-color: lighten($color: $primary, $amount: 1);
    }

    &:hover {
        background-color: darken($color: $primary, $amount: 15);
    }
}

.btn-danger {
    border-radius: 10px;
    padding: 1rem 4rem;
    background-color: #ea5455;
    box-shadow: 0 1px 10px 1px #ea5455!important;
    transition: background-color .3s ease;

    &:hover {
        background-color: darken($color: red, $amount: 10);
    }
}

.btn-picture {
    border-radius: 10px;
    padding: 1rem 4rem;
    background-color: #1088c0;
    box-shadow: 0 1px 10px 1px #101cc0!important;
    transition: background-color .3s ease;

    &:hover {
        background-color: darken($color: blue, $amount: 10);
    }
}

.btn-save {
    border-radius: 10px;
    padding: 1rem 4rem;
    background-color: green;
    box-shadow: 0 1px 10px 1px #54ea61!important;
    transition: background-color .3s ease;

    &:hover {
        background-color: darken($color: green, $amount: 10);
    }
}


.btn-save-modal {    
    border-radius: 10px;
    padding: 1rem 2rem;    
    margin-right: 10px;
    background-color: green;
    box-shadow: 0 1px 10px 1px #54ea61!important;
    transition: background-color .3s ease;

    &:hover {
        background-color: darken($color: green, $amount: 10);
    }
}

.btn-danger-modal {
    margin-left: 10px;

    border-radius: 10px;
    padding: 1rem 2rem;
    background-color: #ea5455;
    box-shadow: 0 1px 10px 1px #ea5455!important;
    transition: background-color .3s ease;

    &:hover {
        background-color: darken($color: red, $amount: 10);
    }
}

.btn-block {
    width: 100%;
}
.btn-sucess{
    background-color: $light-color;
    color: $text-color;
    padding: .5rem 2rem;
    border-radius: 20px;
    border: 1px solid green;
}
.btn-upload{
    background-color: $light-color;
    margin-right: 1rem;
    color: $text-color;
    padding: .5rem 2rem;
    border-radius: 20px;
    border: 1px solid $google-blue;
}
.google-btn {
    cursor: pointer;
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    height: 42px;
    background-color: $google-blue;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);

    transition: box-shadow .3s ease;

    .google-icon-wrapper {
        position: absolute;
        margin-top: 1px;
        margin-left: 1px;
        width: 40px;
        height: 40px;
        border-radius: 2px;
        background-color: $white;
    }
    .google-icon {
        position: absolute;
        margin-top: 11px;
        margin-left: 11px;
        width: 18px;
        height: 18px;
    }
    .btn-text {
        float: right;
        margin: 11px 40px 0 0;
        color: $white;
        font-size: 14px;
        letter-spacing: 0.2px;
    }
    &:hover {
        box-shadow: 0 0 6px $google-blue;
    }
    &:active {
        background: $button-active-blue;
    }
}